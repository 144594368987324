<script setup>
import { ref, computed, onMounted, watch } from 'vue';

import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import WatermarkImage from 'images/watermark2x.png';

import 'vue-advanced-cropper/dist/style.css';
import { Cropper } from 'vue-advanced-cropper';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const cropperEl = ref(null);
const showImageSizeWarning = ref(true);
const watermark = ref(null);

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId, selectorData } = storeToRefs(mediaEditorStore);

const { title, mediaUrl, mediaWidth, mediaHeight } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const imageSizes = computed(() => ({
  width: mediaWidth.value,
  height: mediaHeight.value,
}));

const selectionHeight = computed(() => selectorData.value?.height);
const selectionWidth = computed(() => selectorData.value?.width);

const change = ({ coordinates }) =>
  mediaEditorStore.setSelectionData(coordinates);
const biggerSize = sizes => Math.max(...sizes);
const smallerSize = sizes => Math.min(...sizes);

const getSizeToUse = ({ width, height }) => {
  const ratio = width > height ? height / width : width / height;

  if (width === height)
    return {
      width: smallerSize([mediaHeight.value, mediaWidth.value]),
      height: smallerSize([mediaHeight.value, mediaWidth.value]),
    };

  if (width > height)
    return {
      width: biggerSize([mediaWidth.value, width]),
      height: biggerSize([mediaWidth.value, width]) * ratio,
    };

  return {
    width: biggerSize([mediaHeight.value, height]) * ratio,
    height: biggerSize([mediaHeight.value, height]),
  };
};

const selectionSize = computed(() => {
  return {
    width: selectionWidth.value,
    height: selectionHeight.value,
  };
});
const stencilProps = computed(() => {
  if (!selectorData.value?.ratio) return {};

  return {
    aspectRatio: selectorData.value?.width / selectorData.value?.height,
  };
});
const imageSmallerThanCrop = computed(
  () =>
    imageSizes.value.width < selectorData.value?.width ||
    imageSizes.value.height < selectorData.value?.height
);
const closeNotification = () => (showImageSizeWarning.value = false);
const minWidth = computed(() =>
  selectionWidth.value && !selectionHeight.value ? selectionWidth.value : 100
);
const minHeight = computed(() =>
  selectionWidth.value && !selectionHeight.value ? selectionWidth.value : 100
);

watch(selectorData, async () => {
  let width, height;
  const { custom } = selectorData.value;
  if (custom) {
    width = selectionSize.value?.width;
    height = selectionSize.value?.height;
  } else {
    const sizesToUse = getSizeToUse(selectionSize.value);
    width = sizesToUse.width;
    height = sizesToUse.height;
  }

  cropperEl.value?.setCoordinates(({ imageSize }) => {
    const leftPositioning = (imageSize.width - width) / 2;
    const topPositioning = (imageSize.height - height) / 2;

    return {
      left: leftPositioning,
      top: topPositioning,
      width: width,
      height: height,
    };
  });

  if (imageSmallerThanCrop.value) showImageSizeWarning.value = true;
});

onMounted(() => {
  watermark.value = new Image();
  watermark.value.src = WatermarkImage;
});

const downloadLowResCrop = () => {
  const cropResult = cropperEl.value.getResult();
  const cropCtx = cropResult.canvas.getContext('2d');

  // adding watermark
  const x = cropResult.canvas.width - watermark.value.width;
  const y = cropResult.canvas.height - watermark.value.height;
  cropCtx.drawImage(watermark.value, x, y);

  const link = document.createElement('a');
  link.download = title.value;
  link.href = cropCtx.canvas.toDataURL();
  link.click();
};

const refreshCropper = () => {
  cropperEl.value.refresh();
};

defineExpose({ downloadLowResCrop, refreshCropper });
</script>
<template>
  <div class="cropper-wrapper">
    <SoonaAlert
      v-if="imageSmallerThanCrop && showImageSizeWarning"
      class="cropper-wrapper__image-warning"
      data-cypress="resizer-warning"
      closable
      @close-notification="closeNotification"
    >
      your image size {{ imageSizes.width }}px × {{ imageSizes.height }}px is
      too small for the chosen crop. the export will be lower quality.
    </SoonaAlert>
    <Cropper
      ref="cropperEl"
      class="cropper"
      :src="mediaUrl"
      default-boundaries="fit"
      :resize-image="false"
      :default-size="selectionSize"
      :min-width="minWidth"
      :min-height="minHeight"
      :stencil-props="stencilProps"
      @change="change"
      @ready="refreshCropper"
    />
  </div>
</template>

<style lang="scss">
@use '@/variables';

.cropper-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 30rem;
  max-width: 100%;

  &__image-warning {
    position: absolute;
    min-width: 38rem;
    top: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    align-items: center;
  }
}

.vue-line-wrapper .vue-simple-line {
  border-color: variables.$periwink-blue-70;

  &--east {
    border-right-width: 0.1875rem;
  }
  &--west {
    border-left-width: 0.1875rem;
  }
  &--south {
    border-bottom-width: 0.1875rem;
  }
  &--north {
    border-top-width: 0.1875rem;
  }
}
.vue-simple-handler {
  border-radius: 0.5rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: variables.$gray-30;

  &--west,
  &--east {
    height: 1.25rem;
    width: 0.625rem;
  }

  &--north,
  &--south {
    width: 1.25rem;
    height: 0.625rem;
  }

  &--west-north,
  &--west-south,
  &--east-north,
  &--east-south {
    height: 1rem;
    width: 1rem;
  }
}
</style>
